<template>
    <layout>
        <div class="w-100 h-100 d-flex flex-column">
            <div class="d-flex justify-content-end p-4 pb-0">
                <button @click="goToLogin" class="btn btn-light btn-40">
                    <i class="ri-arrow-left-line"></i>
                </button>
            </div>
            <div class="flex-grow-1 w-100 d-flex flex-column p-4 justify-content-center">
                <div>
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <div class="logo-wrapper mb-5">
                            <img src="@/assets/img/bau-logo-for-light.png" height="40"/>
                        </div>
                        <h4 class="flex-grow-1 text-center mb-5">
                            <span v-if="formData.corporate_type=='in'">{{ $t('in_house_application') }}</span>
                            <span v-else>{{ $t('external_application') }}</span>
                            <br>
                            {{ $t('undergraduate_transfer') }} {{ $t('application_system') }}
                        </h4>
                    </div>
                    <div class="fadeIn">
                        <div v-if="formData.corporate_type=='in'">
                            <h5 class="mb-3">{{ $t('bau_student_application') }}</h5>
                            <ValidationObserver ref="formDataValidation">
                                <div class="border rounded pt-4 pl-4 pr-4 pb-1 bg-white">
                                    <b-row>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('national_id')">
                                                <div class="label-as-input">{{ formData.national_id }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('name')">
                                                <div class="label-as-input">{{ formData.name }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('surname')">
                                                <div class="label-as-input">{{ formData.surname }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('nationality')">
                                                <div class="label-as-input">{{ formData.nationality_name }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('birthdate')">
                                                <div class="label-as-input">{{ this.toLocaleDate(formData.birthdate) }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="4">
                                            <b-form-group :label="$t('father_name')">
                                                <div class="label-as-input">{{ formData.father_name }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col rows="12" md="12">
                                            <ValidationProvider name="telephone" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('telephone')">
                                                    <phone-number-input v-model="formData.phone"
                                                                        :only-countries="['TR']"
                                                                        :no-country-selector="true"
                                                                        :no-example="true"
                                                                        :validateError="errors[0]"
                                                                        :disabled="process != null ? true:false"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="!process">
                                    <processing-button :processing="formStart"
                                                       variant="primary"
                                                       :label="$t('send_sms').toUpper()"
                                                       @click="start"
                                    />
                                </div>
                                <div class="border rounded pt-1 pb-2 pl-4 pr-4 mt-1 mb-4 bg-white" v-if="process">
                                    <div class="smsForm">
                                        <div class="fadeIn">
                                            <ValidationProvider name="pin" :rules="process=='verify' ? 'required|length:6':''" v-slot="{valid, errors}">
                                                <div class="mb-2">
                                                    <sms-input v-model="formData.pin"
                                                               :timerFrom.sync="smsTimer"
                                                               @timerExpired="timerExpired"/>
                                                </div>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" v-if="process">
                                    <processing-button :processing="formStart"
                                                       variant="primary"
                                                       :label="$t('send_sms').toUpper()"
                                                       @click="start"
                                                       v-if="process=='startAgain'"
                                    />
                                    <processing-button :processing="formVerify"
                                                       variant="primary"
                                                       :label="$t('verify').toUpper()"
                                                       @click="verify"
                                                       v-if="process=='verify'"
                                    />
                                </div>
                            </ValidationObserver>
                        </div>
                        <div v-else-if="formData.corporate_type=='external'">
                            <ValidationObserver ref="formDataValidation">
                                <ValidationProvider name="type" v-slot="{ valid, errors }">
                                    <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="formData.type"
                                            :aria-describedby="ariaDescribedby"
                                            button-variant="outline-primary"
                                            name="radio-btn-outline"
                                            buttons
                                            id="applicationTypeButtons"
                                        >
                                            <b-form-radio :value="option.value"
                                                          v-for="(option, optionKey) in typeOptions"
                                                          :key="optionKey"
                                            >
                                                {{ option.text }}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <div class="border p-4 bg-white" style="margin-top: -2px; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px">
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="nationality_code" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('nationality')">
                                                    <country-selectbox v-model="formData.nationality_code"
                                                                       valueType="code"
                                                                       :state="errors[0] ? false:null"
                                                                       :validate-error="errors[0]"
                                                                       :public="true"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <div v-if="formData.nationality_code && formData.nationality_code=='TR'">
                                                <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                                                    <b-form-group :label="$t('national_id')">
                                                        <b-form-input type="number"
                                                                      v-model="formData.national_id"
                                                                      maxlength="11"
                                                                      :state="errors[0] ? false : null"
                                                        />
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="formData.nationality_code && formData.nationality_code!='TR'">
                                                <ValidationProvider name="passport_number" rules="required" v-slot="{ errors }">
                                                    <b-form-group :label="$t('passport_number')">
                                                        <b-form-input v-model="formData.passport_number"
                                                                      :state="errors[0] ? false : null"
                                                        />
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('name')">
                                                    <b-form-input v-model="formData.name"
                                                                  :state="errors[0] ? false:null"
                                                    />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="surname" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('surname')">
                                                    <b-form-input v-model="formData.surname"
                                                                  :state="errors[0] ? false:null"
                                                    />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="father_name" rules="required" v-slot="{errors}">
                                                <b-form-group :label="$t('father_name')">
                                                    <b-form-input v-model="formData.father_name"
                                                                  :state="errors[0] ? false:null"
                                                    />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="birthdate" rules="required" v-slot="{errors}">
                                                <b-form-group :label="$t('birthdate')">
                                                    <select-date v-model="formData.birthdate"
                                                                 :validationError="errors[0]"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="12" v-if="formData.type!='abroad'">
                                            <ValidationProvider name="telephone" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('telephone')">
                                                    <phone-number-input v-model="formData.phone"
                                                                        :only-countries="['TR']"
                                                                        :no-country-selector="true"
                                                                        :no-example="true"
                                                                        :validateError="errors[0]"
                                                                        :disabled="process != null ? true:false"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="12" v-if="formData.type=='abroad'">
                                            <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                                <b-form-group :label="$t('email')">
                                                    <b-form-input v-model="formData.email"
                                                                  :state="errors[0] ? false:null"
                                                    />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="!process">
                                    <processing-button :processing="formStart"
                                                       variant="primary"
                                                       :label="$t('send_sms').toUpper()"
                                                       @click="start"
                                    />
                                </div>
                                <div class="border rounded pt-1 pb-2 pl-4 pr-4 mb-4 bg-white"
                                     style="margin-top: -10px;  border-top-right-radius: 0 !important; border-top-left-radius: 0 !important;"
                                     v-if="process"
                                >
                                    <div class="smsForm">
                                        <div class="fadeIn">
                                            <ValidationProvider name="pin" :rules="process=='verify' ? 'required|length:6':''" v-slot="{valid, errors}">
                                                <div class="mb-2">
                                                    <sms-input v-model="formData.pin"
                                                               :timerFrom.sync="smsTimer"
                                                               @timerExpired="timerExpired"/>
                                                </div>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" v-if="process">
                                    <processing-button :processing="formStart"
                                                       variant="primary"
                                                       :label="$t('send_sms').toUpper()"
                                                       @click="start"
                                                       v-if="process=='startAgain'"
                                    />
                                    <processing-button :processing="formVerify"
                                                       variant="primary"
                                                       :label="$t('verify').toUpper()"
                                                       @click="verify"
                                                       v-if="process=='verify'"
                                    />
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    // Layout
    import Layout from '@/modules/undergraduateTransfer/layout/Layout'

    // Components
    import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox'
    import SelectDate from '@/components/interactive-fields/SelectDate'
    import SmsInput from '@/components/elements/SmsInput'
    import PhoneNumberInput from '@/components/elements/PhoneNumberInput'
    import ProcessingButton from '@/components/elements/ProcessingButton'

    // Services
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Base from '@/plugins/Base'

    export default {
        components: {
            Layout,

            CountrySelectbox,
            SelectDate,
            SmsInput,
            PhoneNumberInput,
            ProcessingButton,

            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                process: null,
                formStart: false,
                formVerify: false,
                formData: {
                    student_program_id: null,

                    corporate_type: null,
                    type: null,
                    nationality_code: null,
                    national_id: null,
                    passport_number: null,

                    name: null,
                    surname: null,
                    birthdate: null,
                    father_name: null,

                    phone: '5067136760',
                    email: null
                },
                smsTimer: null,
                typeOptions: [
                    {value: 'domestic', text: this.$t('domestic_application').toUpper()},
                    {value: 'abroad', text: this.$t('abroad_application')}
                ]
            }
        },
        created() {
            let undergraduateTransferTempData = Base.LocalStorage.get('undergraduate_transfer_temp_data');
            if (undergraduateTransferTempData) {
                this.formData = {
                    ...this.formData,
                    ...undergraduateTransferTempData
                }
                Base.LocalStorage.remove('undergraduate_transfer_temp_data');
            }
            else {
                this.formData.corporate_type = 'external';
                this.formData.type = 'domestic';
                this.formData.nationality_code = 'TR';
            }
        },

        methods: {
            async start() {
                const isValid = await this.$refs.formDataValidation.validate();
                if (isValid) {
                    let formData = {
                        ...this.formData
                    }
                    formData.phone = formData.phone.replace(/[^\d]/g, '');

                    delete formData.nationality_name;
                    delete formData.sms;
                    if (formData.type == 'abroad') {
                        if(formData.nationality_code!='TR'){
                            delete formData.national_id
                        }
                        delete formData.phone
                    }
                    else {
                        delete formData.passport_number
                        delete formData.email
                    }
                    this.formStart = true
                    UndergraduateTransferService.start(formData)
                                                .then(response => {
                                                    this.process = 'verify'
                                                })
                                                .then(() => {
                                                    this.smsTimer = 120
                                                })
                                                .catch(e => {
                                                    if (e.status == 423) {
                                                        this.$toast.error(this.$t('api.' + e.data.message));
                                                        setTimeout(() => {
                                                            this.$router.push('/transfer/login');
                                                        }, 1000)
                                                    }
                                                    else {
                                                        this.showErrors(e, this.$refs.formDataValidation)
                                                    }
                                                })
                                                .finally(() => {
                                                    this.formStart = false
                                                })
                }
            },

            async verify() {
                const isValid = await this.$refs.formDataValidation.validate();
                if (isValid) {
                    let formData = {
                        ...this.formData
                    }
                    formData.phone = formData.phone.replace(/[^\d]/g, '');

                    if (formData.type == 'abroad') {
                        if(formData.nationality_code!='TR'){
                            delete formData.national_id
                        }
                        delete formData.phone
                    }
                    else {
                        delete formData.passport_number
                        delete formData.email
                    }

                    this.formVerify = true;
                    UndergraduateTransferService.verify(formData)
                                                .then(response => {
                                                    let data = response.data.data;
                                                    let loginForm = {
                                                        'username': data.national_id ? data.national_id : data.passport_number,
                                                        'pin': data.pin
                                                    }

                                                    // Login
                                                    UndergraduateTransferService.login(loginForm)
                                                                                .then(response => {
                                                                                    let data = response.data.data;

                                                                                    // Data
                                                                                    if (data) {
                                                                                        this.$store.commit('undergraduateTransfer/setData', data)
                                                                                        Base.LocalStorage.set('undergraduate_transfer_data', data)
                                                                                    }

                                                                                    // Username
                                                                                    let username = data.undergraduate_transfer.national_id ?
                                                                                        data.undergraduate_transfer.national_id :
                                                                                        data.undergraduate_transfer.passport_number;
                                                                                    if (username) {
                                                                                        this.$store.commit('undergraduateTransfer/setUsername', username)
                                                                                        Base.LocalStorage.set('undergraduate_transfer_username', username)
                                                                                    }

                                                                                    // Pin
                                                                                    if (data.undergraduate_transfer.pin) {
                                                                                        this.$store.commit('undergraduateTransfer/setPin', data.undergraduate_transfer.pin)
                                                                                        Base.LocalStorage.set('undergraduate_transfer_pin', data.undergraduate_transfer.pin)
                                                                                    }
                                                                                })
                                                                                .then(() => {
                                                                                    this.$router.push('/transfer');
                                                                                })
                                                })
                                                .catch(e => {
                                                    this.showErrors(e, this.$refs.formDataValidation)
                                                })
                                                .finally(()=>{
                                                    this.formVerify = false
                                                })
                }
            },
            timerExpired(event) {
                this.smsTimer = null
                this.process = 'startAgain'
            },
            goToLogin() {
                this.$router.push('/transfer/login');
            }
        }
    }
</script>

<style>
    #applicationTypeButtons {
        width: 100%;
        display: flex;
    }

    #applicationTypeButtons label {
        flex-grow: 1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    #applicationTypeButtons label.active {
        background: #00aeef !important;
        color: #fff !important;
        position: relative;
        z-index: 10;
    }

    #applicationTypeButtons label.active::after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        z-index: 10;
        border: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin-left: -6px;
        left: 50%;
        border-top: 6px solid #00aeef;
        bottom: -6px;
    }

</style>
